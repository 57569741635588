import {React, useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import Footer from './Footer';
import { 
  TwitterShareButton,
  TelegramShareButton,
  PinterestShareButton,
  RedditShareButton, 
  WhatsappShareButton,
  TwitterIcon,
  TelegramIcon,
  PinterestIcon,
  RedditIcon, 
  WhatsappIcon
} from 'react-share';
import './Grid.css';

const images = [ 
  {
    thumbnail: '/memes/financialfreedom.png',
    fullImage: 'https://arweave.net/fHZLAKtIdv6sEArhGdcWDWsBp8HI5e2yAzTAv1YUVOw',
    caption: `"togethur strong"`
  },
  {
    thumbnail: '/memes/president.png',
    fullImage: 'https://arweave.net/VahuSHQ2ofVQ5gedQgSp_hoGtarqQcnG2Awpo1rX1HA',
    caption: `"💯 inawgerashun"`
  },
  {
    thumbnail: '/memes/ahaha.png',
    fullImage: 'https://arweave.net/iz2zq68VQKfjdQsJ_VXdsVjWPkuYYjjNqyVggD_fjy0',
    caption: `"gm ahhaha"`
  },
  {
    thumbnail: '/memes/fakebelt.gif',
    fullImage: 'https://arweave.net/LwhnsIU5yz2n6jhM6xH2qWMD3nORc5g4VI7lAsymbJQ',
    caption: `"fake transakshuns"`
  },
  {
    thumbnail: '/memes/sellbags.png',
    fullImage: 'https://arweave.net/ranXX4UUZvSrsfMHoYgmi_V7-cw68FGRwEfvhFWtRD0',
    caption: `"j.p. hoarding"`
  },
  {
    thumbnail: '/memes/gudmorneeng.gif',
    fullImage: 'https://arweave.net/gTnPjWcPdhW3ki3ciqc-PhuuALKUa4sunfNi8wdCkzw',
    caption: `"gud morneeng"`
  },
  {
    thumbnail: '/memes/Poopcoin AI.png',
    fullImage: 'https://arweave.net/jHHBbvlFLJFdp51X0cESN3PQVaBpN70jcUcoAWcnYKw',
    caption: `"poopcoin ai"`
  },
  {
    thumbnail: '/memes/Rocky GM.png',
    fullImage: 'https://arweave.net/lqfH_G4pX1ZNLat4SLOi3lSM8tYoNPusikS3lHTV5_0',
    caption: `"ay yo GM!"`
  },
  {
    thumbnail: '/memes/pump.png',
    fullImage: 'https://arweave.net/WyG932R0fyG5HOb6pECU5jU5GRwXsHPJxpN4f6sWEy0',
    caption: `"let's pump tgethr"`
  },
  {
    thumbnail: '/memes/mistlehundo.gif',
    fullImage: 'https://arweave.net/GzvV2EDqPVA2FQGvOF655XO5QE_G1LapGZeAxOp6IHs',
    caption: `"mistlehundo"`
  },
  {
    thumbnail: '/memes/dinner.gif',
    fullImage: 'https://arweave.net/v6DgEiBUyi_gq5SiHGuMza22qyEDmrvlGVVZ_N0rQEo',
    caption: `"spred de wurd"`
  },
  {
    thumbnail: '/memes/picnic.gif',
    fullImage: 'https://arweave.net/uDh-ER-LeqyZ1SQ5J7ObMvmRfBUB9Yk1z5G7V3xklLU',
    caption: `"tell 'em picnic"`
  },
  {
    thumbnail: '/memes/Stop Printing Bro.png',
    fullImage: 'https://arweave.net/0ueq973cxS2pqRqveq7E6Mk0Diq5WhIp2WlbPGidlT8',
    caption: `"brrr"`
  },
  {
    thumbnail: '/memes/U jus want da chips.png',
    fullImage: 'https://arweave.net/nh5kayuaqiXSsC93_ooRuvuOTrSKK2jDPyYXtksGbdA',
    caption: `"U jus want da chipz"`
  },{
    thumbnail: '/memes/Fud N Buy.png',
    fullImage: 'https://arweave.net/fSq7s7Yw_grW4o4a8czZTImIhvaymleHYOU-4WwgPlQ',
    caption: `"wat fudders doo"`
  },
  {
    thumbnail: '/memes/buycrypto.png',
    fullImage: 'https://arweave.net/9H_snixdl4iK3UqrzR4JUO4RPhAfXmpdjtr3QE8674g',
    caption: `"shuld u buy crypto"`
  },
  {
    thumbnail: '/memes/Legend.png',
    fullImage: 'https://arweave.net/_psZ_x_i-t-xXH4JQkm1Cdxo7hsUzMCFsLHqfZf8WOs',
    caption: `"legend"`
  },
  {
    thumbnail: '/memes/Train GM.png',
    fullImage: 'https://arweave.net/sSUS3Dj2KELN7u0beKZ7-D1vu9bF3Hqh9kNB3XCqP3s',
    caption: `"make yur own"`
  },
  {
    thumbnail: '/memes/Hundred Christmas.png',
    fullImage: 'https://arweave.net/Vd11udzrMr0QAOF-BDn22iI1i1OQUqMwDLIUyPIETCgy',
    caption: `"💯no daze off"`
  },
  {
    thumbnail: '/memes/Good Vibes.png',
    fullImage: 'https://arweave.net/RDUCGMYJJKfJULgRuTcO4CPU-3Z_iIdm4z2cD6Q_8DM',
    caption: `"gud vibes 💯 "`
  },
  {
    thumbnail: '/memes/Positive Vibes.png',
    fullImage: 'https://arweave.net/9hXTqMi8EP8CGznukazjn-2HpBlSW7bKhZLm1v_r17s',
    caption: `"positiv vibes💯 "`
  },
  {
    thumbnail: '/memes/Feed da Baby.png',
    fullImage: 'https://arweave.net/BF6-FsNP4ChyLC60Wq2rlAb4E2Ktz3f8Q5DiLItIS4M',
    caption: `"💯 Dont Feed da FUD"`
  },
  {
    thumbnail: '/memes/JeetGM.png',
    fullImage: 'https://arweave.net/sJdxwyY68PT5fMnVs_MsMoj4zL4VhvtaMQK--joArQ0',
    caption: `"Gud Morneeng 💯"`
  },
  {
    thumbnail: '/memes/Hundred Protects You.gif',
    fullImage: 'https://arweave.net/ncyK0IeJmPf85RzfZA3nUdnUT3jiC12Gcu-EcLALLno',
    caption: `"💯Protects You"`
  },
  {
    thumbnail: '/memes/We Are Here.png',
    fullImage: 'https://arweave.net/9KVBC_YDj67qPaVdbxNX_waSna3FZsarUucr1OPbwQ4',
    caption: `"We are Here 💯"`
  },
  {
    thumbnail: '/memes/billdn.gif',
    fullImage: 'https://arweave.net/ULhCakOhZbPYZr_jzgrkOLgrksaokoHof1FnUY9BGdw',
    caption: `"💯 Billdn 4 Avax"`
  },
  {
    thumbnail: '/memes/CryptoBro.gif',
    fullImage: 'https://arweave.net/ksVf_WHOHpEQAOgNoqenEbf_BX3KQ8yLffEKO9pUqEI',
    caption: `"Crypto Bro"`
  },
  {
    thumbnail: '/memes/Back to School.gif',
    fullImage: 'https://arweave.net/WeZoXfzKguFoCmBZDx21q3e68NrkK0pB4tHkUh79eiI',
    caption: `"Back to School"`
  },
  {
    thumbnail: '/memes/Do Something.gif',
    fullImage: 'https://arweave.net/XtU5wcQpXR8lk-kDYERlDzklIpSUixsVysl6vLCUDrg',
    caption: `"Do Sumethin"`
  },
  {
    thumbnail: '/memes/Waytn 4 My Frens.png',
    fullImage: 'https://arweave.net/X7TdAhJqPf8Kglx3EZ-OypSj6ql6B8CKCRj8FEm6JKE',
    caption: `"Waytn 4 My Frens"`
  },
  {
    thumbnail: '/memes/Stay Active!.gif',
    fullImage: 'https://arweave.net/SVElawk5xFPebtswg2Zna80E1UkCqANynBkXocSI4wo',
    caption: `"Stay Active!"`
  },
  {
    thumbnail: '/memes/Congrats.png',
    fullImage: 'https://arweave.net/CPcT34m8w2O_81EFEv9Tu5ixb9d_kxsUXdaJcm7q7TU',
    caption: `"Congratz Bostun!"`
  },
  {
    thumbnail: '/memes/Why Buy Crypto1.png',
    fullImage: 'https://arweave.net/qqpv1x2UOpjj8UBX3IaR1rCIMiYEkhahf38sW5YX_KQ',
    caption: `"Why Buy Crypto?"`
  },
  {
    thumbnail: '/memes/We Are 100.gif',
    fullImage: 'https://arweave.net/25XsQ5rq7Dmnp6mwOmOpO5r4CPbpwBEJZIo2J9Fz94Q',
    caption: `"We Are 100"`
  },
  {
    thumbnail: '/memes/eat.png',
    fullImage: 'https://arweave.net/jsSarL1PX0VcFy9Bm6eQWkYiJUYn-vCIpLmOLBfFZYo',
    caption: `"Eat the Dip"`
  },
  {
    thumbnail: '/memes/dont.png',
    fullImage: 'https://arweave.net/xtaZE4yyu9m8TGJF11JD_wK91GzBxn4tHR71tVtSXpY',
    caption: `"💯Gang"`
  },
  {
    thumbnail: '/memes/Breakfast.png',
    fullImage: 'https://arweave.net/iNfjUUqQuk_P58MByV4Hp4d37_9ORKGpt4ky39MZzz8',
    caption: '"The First Meme"'
  },
  {
    thumbnail: '/memes/Baby Blue Bed and Yellow Floor.png',
    fullImage: 'https://arweave.net/8vEXPG4JSxfJ2ZxxVrpwz1nllVCD_Sbgw6si8tVf-WE',
    caption: `"It's a Boy!"`
  },
  {
    thumbnail: '/memes/Basketball.png',
    fullImage: 'https://arweave.net/68uRmYrsuSaIKXAS4bzITSyRoKZe7p0jhYY3bB2Y4io',
    caption: '"💯Dunk"'
  },
  {
    thumbnail: '/memes/Bathroom Detailed.png',
    fullImage: 'https://arweave.net/ivbCEqF8aSuKXux2y3W9q9Z93Qx3IR9LrlahzMFyS_c',
    caption: '"Bafroom"'
  },
  {
    thumbnail: '/memes/PoopPortfolio.png',
    fullImage: 'https://arweave.net/377JJTyx8UiPMqsnTy0bDgyKC8Lwo1fiMlRGiwg4nXs',
    caption: '"Poop Portfolio"'
  },
  {
    thumbnail: '/memes/Beach.png',
    fullImage: 'https://arweave.net/sFyJDSsp-medoCKhO3QrLWMvOkhSn24vo33atPFHNSg',
    caption: '"Chillin💯"'
  },
  {
    thumbnail: '/memes/Bear Dont Eat Us.png',
    fullImage: 'https://arweave.net/vlCVLAeKKxutY-6Y4Fv896umsCpAeaRhm717TY_N3AU',
    caption: `"Shulda Held💯"`
  },
  {
    thumbnail: '/memes/Boat.png',
    fullImage: 'https://arweave.net/6KRMfHro8cfAimMrSgUwaFzrHu-wJWGn_TqrTZlxiz0',
    caption: '"Shulda Bought"'
  },
  {
    thumbnail: '/memes/Bit Bot.png',
    fullImage: 'https://arweave.net/FkYB15YImtQWFSwWbL_lBBY8oNLsoZGrsaBiHYIS2lE',
    caption: '"Bit Bot"'
  },
  {
    thumbnail: '/memes/Blackrock Pump Bags 10x.png',
    fullImage: 'https://arweave.net/9mvzY5M1cPF0VyKqNj_qCANp1-7Kz1NvG-YlI-rJaCk',
    caption: '"Black Rock"'
  },
  {
    thumbnail: '/memes/Board Games.png',
    fullImage: 'https://arweave.net/cxrpH5TBV_EYAwsYIE9kW07Az_MZMSCcPCoq41KGTRw',
    caption: '"Gamblin"'
  },
  {
    thumbnail: '/memes/Cheers.png',
    fullImage: 'https://arweave.net/WCdg52muKqSvYxc4jAVOZSWmZjnZOpnbJVv2xQfjeTk',
    caption: '"Cheers"'
  },
  {
    thumbnail: '/memes/Boss vs. Worker. vs. Dev.png',
    fullImage: 'https://arweave.net/9LxAkjZYMfzKiQYpS5o8EBgUo4WXB3wUOdkfjrvCrkY',
    caption: '"Poor Dev"'
  },
  {
    thumbnail: '/memes/Boyfriend.png',
    fullImage: 'https://arweave.net/-DMBVJv8JdBL_lefsviUCcr0d4XxIGnrb1GUclnW3_s',
    caption: '"Boyfriend💯"'
  },
  {
    thumbnail: '/memes/Burger 3.png',
    fullImage: 'https://arweave.net/5EwDrowzbMo4ECx4IoRk8tlxbKlZWV-mOrMRSQh6ypg',
    caption: '"No Ketchup"'
  },
  {
    thumbnail: '/memes/Classroom Edit.png',
    fullImage: 'https://arweave.net/NuwxRZto9NgJTeXyUi-P9eyImm3atLDeIQ3KZQFjQ0E',
    caption: '"Meme Coin Class"'
  },
  {
    thumbnail: '/memes/Club.png',
    fullImage: 'https://arweave.net/smJtmyzgM1zzXB5qdB1fYzq9V3WYLoGIITAEmTyugu0',
    caption: '"Coinrekkdo"'
  },
  {
    thumbnail: '/memes/Coffee Detailed.png',
    fullImage: 'https://arweave.net/A6PrrHu2UGsoDaR_sVfbSJuy1NTBnYonmNZKo6kaPLc',
    caption: '"Guud Morneng"'
  },
  {
    thumbnail: '/memes/Club Timer Meme (1).png',
    fullImage: 'https://arweave.net/TlaN5JScYSh_nsAAVBDDarO-gzpmVPxoHLpObFLmGU4',
    caption: `"Da Club💯"`
  },
  {
    thumbnail: '/memes/Regulation.png',
    fullImage: 'https://arweave.net/VBqiRZj6QTZuqYUzyFO257jx3H6KflaMlOlzHmJs9hk',
    caption: `"Stan wit CZ💯"`
  },
  {
    thumbnail: '/memes/Roach.png',
    fullImage: 'https://arweave.net/6rmfqJAre6f3hEzOzl79EtGzYCkA2K_mOaz2SFXt1lY',
    caption: `"Roach Shill"`
  },
  {
    thumbnail: '/memes/Rock Paper Scissors Hands.png',
    fullImage: 'https://arweave.net/ubz6BwVpBJbkTUEqDTphMGcTIh8OXdoao5gpn1l7TGs',
    caption: `"Sizzer"`
  },
  {
    thumbnail: '/memes/Run Jeets.gif',
    fullImage: 'https://arweave.net/ltBnBT0R2ZCJyMjyCWXx8ZUEKM-wyhH5lUr8cQPwIqY',
    caption: `"Run Jeets!"`
  },

  {
    thumbnail: '/memes/Car Ride.png',
    fullImage: 'https://arweave.net/0yDRSoFKBiH-sOJRpx30Udt7mJfevz5KC0Y-Vo_HgL8',
    caption: '"Kick Out"'
  },
  {
    thumbnail: '/memes/Couple.png',
    fullImage: 'https://arweave.net/9a-zOeu54kXzF7JZvQn0orfovOtfGj_-nULyug3Kjk4',
    caption: '"Jeet Block"'
  },
  {
    thumbnail: '/memes/Dog.png',
    fullImage: 'https://arweave.net/7N34mAeqEwmbaXZ1BoM0qfg9HsKntF_XfZARyicjVu4',
    caption: '"💩coin"'
  },
  {
    thumbnail: '/memes/Drive Thru.png',
    fullImage: 'https://arweave.net/aTCJSIMRxIYPExNXn9JH5p8aD20AVhJ_iACFbmbCyKo',
    caption: '"Drive-Thru💯"'
  },
  {
    thumbnail: '/memes/Dumb Chart.png',
    fullImage: 'https://arweave.net/gCrwyNrp5YOla12ZJ6gpBEaHCoEE9kQSaUfzFNAlL80',
    caption: '"Charts"'
  },
  {
    thumbnail: '/memes/Farmer.png',
    fullImage: 'https://arweave.net/tzdHzOi379lC3QBDqP2jhjRpskRsnxr132Quhy0BlMg',
    caption: '"Who did dis?"'
  },
  {
    thumbnail: '/memes/Fishing.png',
    fullImage: 'https://arweave.net/g7er0FWWjXAhyJ4zU8XZ9MqkgTL4qAME0NmtBdZlr-A',
    caption: '"Fishing"'
  },
  {
    thumbnail: '/memes/Football.gif',
    fullImage: 'https://arweave.net/_I-PajbHCPriztz0jm3URozQnTulCXMtAj-_FyWSOwI',
    caption: '"Stiff Arm💯"'
  },
  {
    thumbnail: '/memes/Forest.png',
    fullImage: 'https://arweave.net/cN4pdziBvgT25vjXeWnpLld5k7isuT416lBGoQUEv0s',
    caption: '"Gem Hunters"'
  },
  {
    thumbnail: '/memes/Gecko.png',
    fullImage: 'https://arweave.net/TnTHODViubOWvTEsHUVUjn5Yqb7cWdkgL26_uUtr-RY',
    caption: '"Gecknapped"'
  },
  {
    thumbnail: '/memes/sam.png',
    fullImage: 'https://arweave.net/ZcJUVGCL6iryRdawqUsnL2TitJijW_ZsT_xcl0I7NI0',
    caption: '"Did Sam Hurt U?"'
  },
  {
    thumbnail: '/memes/Girly Movies.png',
    fullImage: 'https://arweave.net/xMCZiMz61a5mZRebRF9AwCUFCq8VyMxale_CcP_t5Y4',
    caption: '"Da Movies💯"'
  },


  {
    thumbnail: '/memes/Golf Meme Cake Richquack Different Shirt.png',
    fullImage: 'https://arweave.net/uqMR9IfFydjOf3BCGxib2AtDnArpcfXBL9galrnc_y0',
    caption: '"Golf 1💯"'
  },
  {
    thumbnail: '/memes/Golf New.png',
    fullImage: 'https://arweave.net/tgTIK9kqHs-mPJxEFQyNTwOf7Y_q4Ym9ipyc8Covlic',
    caption: '"Golf 2💯"'
  },
  {
    thumbnail: '/memes/Gotta Sell.png',
    fullImage: 'https://arweave.net/qdjVXad3AhGTfY-D9_Yv62GLEfJ8Mb5hPocKGiprnuw',
    caption: '"Reelax💯"'
  },
  {
    thumbnail: '/memes/Homeless.png',
    fullImage: 'https://arweave.net/5uul---f830dFtYj3UGF78mPgQWhbODlJtZVYRBalzM',
    caption: '"Homeless"'
  },
  {
    thumbnail: '/memes/Hospital 2.png',
    fullImage: 'https://arweave.net/ZjZEuc5hilAhpvX0oPvxSe75SSaRyoLjpVjoJ0Grc_g',
    caption: '"ER"'
  },
  {
    thumbnail: '/memes/Hugging or Standing Together.png',
    fullImage: 'https://arweave.net/tuc0BwGIw3DAaSYsAuRuI229NcFgh1Uv-xzY0xbq67Q',
    caption: '"Family💯"'
  },
  {
    thumbnail: '/memes/Hundred Cookie Meme (1).png',
    fullImage: 'https://arweave.net/3Sh8RQ2ksZdiZD2kFlBdnBEPsIRa_5NfKSUusQxYUXo',
    caption: '"Cookie Monsters"'
  },
  {
    thumbnail: '/memes/Phone Call.png',
    fullImage: 'https://arweave.net/JTiRWtKYtuAVq9HBxrsweDGjKdQ6xrtFW9qEgreXcmc',
    caption: '"Seed Words"'
  },
  {
    thumbnail: '/memes/Playing With Gecko.png',
    fullImage: 'https://arweave.net/nJZFeL4BmPsk9NV1TxVs2niZ-k0i57FIiI3XBZoxu58',
    caption: '"Gamin wit gecko"'
  },
  {
    thumbnail: '/memes/Pray.png',
    fullImage: 'https://arweave.net/MOeHo1I86kNHZDGBByCpy7wQJy-xZcOfbkBoSV7JM6c',
    caption: '"I Pray"'
  },
  {
    thumbnail: '/memes/Race 100 Face.png',
    fullImage: 'https://arweave.net/FaTBhTjbFDcOVg4OwWHKIw8jWYUoikLRiKhQORymFxY',
    caption: '"Meme Coin Race"'
  },
  {
    thumbnail: '/memes/Crap Coin Converter.gif',
    fullImage: 'https://arweave.net/F5LuRBmLpRS8OeG73kfJv4ye2tLEspQ-TTDdX1xZMJY',
    caption: '"Crap Converter"'
  },
  {
    thumbnail: '/memes/Hundred Fixed Crypto.png',
    fullImage: 'https://arweave.net/F2b8P-OV60-jSKlatUbqjd5l2-DJXM3k8N4J3V2eaMs',
    caption: '"💯Fixe Crypto"'
  },
  {
    thumbnail: '/memes/Influencer 2.png',
    fullImage: 'https://arweave.net/b2X6KokIvMQLaitau3MAEuXZu-JqiduxSwhMKqFJujU',
    caption: '"Influenza"'
  },
  {
    thumbnail: '/memes/Jeet Birds Text.png',
    fullImage: 'https://arweave.net/UtpXeLaLf2aSeNGfsylu9WDaCK0gLldn-J9LblrAL6w',
    caption: '"Jeet Birds"'
  },
  {
    thumbnail: '/memes/Massage Wen Lambo.png',
    fullImage: 'https://arweave.net/CGs950h22k73Xpxe8GKkjE09VFkLPjJVCna9QwyGcmY',
    caption: '"Wen Lambo?"'
  },
  {
    thumbnail: '/memes/Me or Hundred.png',
    fullImage: 'https://arweave.net/lrJ3_ghTG8gxnrXcvavFd4fYKm7BqhgePciWUfSEHqg',
    caption: '"Bye Felicia"'
  },
  {
    thumbnail: '/memes/Movie Night.png',
    fullImage: 'https://arweave.net/9h0KDxt1_DSMBtwZyrelcKRXHdsPr-2JNo23PE0AIZA',
    caption: '"Hundred N Chill"'
  },
  {
    thumbnail: '/memes/My Portfolio Farmer.png',
    fullImage: 'https://arweave.net/cxrpH5TBV_EYAwsYIE9kW07Az_MZMSCcPCoq41KGTRw',
    caption: '"Sprinkle💯"'
  },
  {
    thumbnail: '/memes/Nail Salon.png',
    fullImage: 'https://arweave.net/NQ4VoxCstZtzKcizs3wRGzNT_XLXlksWFKDlkczQ204',
    caption: '"Nail Salon"'
  },
  {
    thumbnail: '/memes/New Gender Reveal.png',
    fullImage: 'https://arweave.net/xvqhwXRS1qIfBDHaRF4PD2Xmzc15rnukXiv9zm3_sqQ',
    caption: '"Gender Reveal"'
  },
  {
    thumbnail: '/memes/Office Pig.png',
    fullImage: 'https://arweave.net/qJJ__CdX8HWWZ_pATZxgbm6QwlWHkUFKjeTKC4yZ1Rc',
    caption: '"The Office"'
  },
  {
    thumbnail: '/memes/Parachute 2.png',
    fullImage: 'https://arweave.net/21TBcFWooof_F_jJv34S7Zv9TsPFry0yILtpZbA-9z4',
    caption: '"Parachute"'
  },
  {
    thumbnail: '/memes/Diamond Island Slide 1.png',
    fullImage: 'https://arweave.net/RC1O69Zgi97ZIx59Y3DtIgBAKV_y1wm3ymHWsJu9Atc',
    caption: '"Diamond Island"'
  },
  {
    thumbnail: '/memes/Step on Rug.png',
    fullImage: 'https://arweave.net/6nqC6wggx0fCaf8L4WZ2oOvU8sCIMGYskP07qV5Lm4Q',
    caption: '"Magic Carpet"'
  },
  {
    thumbnail: '/memes/School.png',
    fullImage: 'https://arweave.net/msMpxEWVCkBaSeISMEHxYP1Jo27gcxqqt-lQXYtKAMQ',
    caption: '"Cool Kids💯"'
  },
  {
    thumbnail: '/memes/Sleep Meme Bigger.gif',
    fullImage: 'https://arweave.net/n67P1bZoVMc3Aw_R8HK_-lbNPH8xhKmDICXzBbZdL1c',
    caption: '"Sleep"'
  },
  {
    thumbnail: '/memes/Tea Meme.png',
    fullImage: 'https://arweave.net/9YYQ2CuxiY_npQZmGZSe13C3Cgj4vCd90ZHtHV3k8NY',
    caption: '"Wat U Say?"'
  },
  {
    thumbnail: '/memes/Thanksgiving.png',
    fullImage: 'https://arweave.net/ElLrhmFXFUszF3w2Cma5IfOp1RHx0Dh4l-sN7sSIpqE',
    caption: '"A Chair 4 CZ"'
  },
  {
    thumbnail: '/memes/Thong.png',
    fullImage: 'https://arweave.net/saAWQLpuTzgTnpcIIMSgDGUAvsOX5VvHHfDPIzXQre8',
    caption: '"Thong"'
  },
  {
    thumbnail: '/memes/Toilet.png',
    fullImage: 'https://arweave.net/iwhnCWnyq_c28_EFHI069j19V8cFgmZFlR2-Ni4HxqQ',
    caption: '"Toilet💯"'
  },
  {
    thumbnail: '/memes/Toshi.png',
    fullImage: 'https://arweave.net/Yvba9ns4kvzNTJppcJ-QBW9PpBGa6JatF937-TmMmcg',
    caption: '"Wen Marketing?"'
  },
  {
    thumbnail: '/memes/Trading.png',
    fullImage: 'https://arweave.net/SofKVefEnTmNfzSd7RiqqgvgMPK40EpJH9GfTvzgkLw',
    caption: '"Average Jeet"'
  },
  {
    thumbnail: '/memes/Urinal.png',
    fullImage: 'https://arweave.net/sayER8B-4jmiXjG37TAKxMfvXc02WaRWfRX1fUhzM8g',
    caption: '"Urinal💯"'
  },
  {
    thumbnail: '/memes/Video Games 2.png',
    fullImage: 'https://arweave.net/Ydujd0D-EHRF5zXvzg_GsTzeAEr9TdiERvo5butjaco',
    caption: '"Hodlin💯"'
  },
  {
    thumbnail: '/memes/Waffles.png',
    fullImage: 'https://arweave.net/RJB39iTKbQmQVP6eHAVBIP_qIDzz-zHuHwNlsm6Hzcw',
    caption: '"Waffles"'
  },
  {
    thumbnail: '/memes/Wedding.png',
    fullImage: 'https://arweave.net/C4Ipf13KnjgkZ3yoGNO3a7btAOZklybXSW7bhLV464Q',
    caption: '"I Want Half💯"'
  },
  {
    thumbnail: '/memes/Carnival.png',
    fullImage: 'https://arweave.net/eoX2sGBJpimk-pbYjEaPyFf1RbejR2Cvg9Kr8ET-4bM',
    caption: '"Fair"'
  },
  {
    thumbnail: '/memes/Sports Car Slide 1.png',
    fullImage: 'https://arweave.net/MwbyfST0YV97d_zz61oc3uzCPPckDYncjkI5pWopELs',
    caption: '"Welcome Home"'
  },
];

const Grid = () => {
  const [visibleShareIndex, setVisibleShareIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const updateItemsPerPage = () => {
    if (window.innerWidth < 480) {
      setItemsPerPage(6);
    } else if (window.innerWidth < 768) {
      setItemsPerPage(6);
    } else if (window.innerWidth < 1024) {
      setItemsPerPage(6);
    } else {
      setItemsPerPage(12);
    }
  };

  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);
    return () => {
      window.removeEventListener('resize', updateItemsPerPage);
    };
  }, []);

  const offset = currentPage * itemsPerPage;
  const currentItems = images.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(images.length / itemsPerPage);

  const toggleShareButtons = (index) => {
    setVisibleShareIndex(visibleShareIndex === index ? null : index);
  };


  return (
  <div> 
    <div className="grid-container">
    {currentItems.map((image, index) => (
      <div className="grid-item" key={offset + index}>
        <a href={image.fullImage} target="_blank" rel="noopener noreferrer">
          <img src={image.thumbnail} alt={`Thumbnail ${offset + index + 1}`} className="thumbnail" />
        </a>
        <div className="image-info">
          <div className="text-and-icon">
          <span className="custom-text">{image.caption}</span>
            <FontAwesomeIcon
              icon={faShareFromSquare}
              className="share-icon"
              onClick={() => toggleShareButtons(index)}
            />
          </div> 
            {visibleShareIndex === index && (
              <div className="share-buttons-popout">
                <TwitterShareButton url={image.fullImage} title="Check out this HUNDRED meme!">
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <TelegramShareButton url={image.fullImage} title="Check out this HUNDRED meme!">
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <PinterestShareButton url={image.fullImage} title="Check out this HUNDRED meme!">
                  <PinterestIcon size={32} round />
                </PinterestShareButton>
                <RedditShareButton url={image.fullImage} title="Check out this HUNDRED meme!">
                  <RedditIcon size={32} round />
                </RedditShareButton>
                <WhatsappShareButton url={image.fullImage} title="Check out this HUNDRED memes!">
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
    <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
      <Footer />
    </div>
  );
};

export default Grid;
